
	// vue
	import { defineComponent, ref, inject } from "vue";

	// compnents
	import vueModal from "@/components//vueModal.vue";
	import textInput from "@/components/textInput.vue";
	import emailInput from "@/components/emailInput.vue";
	import phoneInput from "@/components/phoneInput.vue";
	import vueTextarea from "@/components/vueTextarea.vue";
	import checkboxInput from "@/components/checkboxInput.vue";
	import vueForm from "@/components/vueForm.vue";
	import vueClipboard from "@/components/vueClipboard.vue";
	// mixins
	import loading from "@/typeScript/common/screenLoading";
	import notify from "@/typeScript/common/notify";

	export default defineComponent({
		name: "Contact",

		components: {
			vueModal,
			vueForm,
			textInput,
			emailInput,
			phoneInput,
			checkboxInput,
			vueTextarea,
			vueClipboard
		},
		setup() {
			const showForm = ref(false);
			const sender = ref("");
			const email = ref("");
			const phone = ref("");
			const comment = ref("");
			const options = ["phone", "email"];
			const preffered = ref(options[1]);
			const CONFIG = {
				"Content-Type": "text/plain",
				"Access-Control-Allow-Origin": "*"
			};
			// global property
			const EMITTER: any = inject("$emitter");
			const AXIOS: any = inject("$axios");

			// mixins
			loading();
			const { alert, alertObject } = notify();

			const sendMail = function() {
				console.log("sendMail");
				AXIOS.post(
					"http://localhost:5001/portfolio-website-689b4/us-central1/router/api/notification/email",
					{
						to: "hrishirich619@gmail.com",
						name: sender.value,
						subject: "Kkiokio.com Contact Form",
						cc: email.value,
						phoneNumber: phone.value,
						preffered: preffered.value,
						content: comment.value
					}
					// CONFIG
				)
					.then(response => {
						console.log("sendMail successful");
						EMITTER.emit("alert", {
							type: "success",
							message: "Email sent to Kkiokio(Hrishikesh Karale)",
							description:
								"Please wait wait for a couple of days and Kkiokio will get back to you using your prefered method of communication.",
							dismissable: true,
							timeout: 8
						});
					})
					.catch(error => {
						console.error("sendMail FAILED", error);
						EMITTER.emit("alert", {
							type: "warning",
							message: "Error sending email.",
							description: error.response,
							dismissable: true,
							code: "101.1",
							timeout: 8
						});
					});
			}; //sendMAil

			return {
				showForm,
				sender,
				email,
				phone,
				comment,
				options,
				preffered,
				alert,
				alertObject,
				sendMail
			};
		}
	});
